
import * as React from "react";
import { SquidexFaqItem } from "generated/gatsbyApiTypes";
import { getLanguageValue } from "utils";
import FaqItem from "./faqItem";


export interface FaqItemCollectionProps {
  nodes: SquidexFaqItem[];
}

export type FAQGrouping = {
  [key: string]: SquidexFaqItem[] 
}

export default class FaqItemCollection extends React.Component<FaqItemCollectionProps> {
    render() {
      const grouping = this.props.nodes.reduce((acc, next) => {
        const category = getLanguageValue(next.category);  
        if (Object.keys(acc).indexOf(category) !== -1) {
          acc[category].push(next);
        } else {
          acc[category] = [next];
        }
        return acc;
      }, {} as FAQGrouping);
      return <div>
        {Object.keys(grouping).map(g => (
          this.renderCategory(g, grouping[g])
        ))}
      </div>
    }
    renderCategory = (category: string, nodes: SquidexFaqItem[]) => {
      return (
        <div key={`FAQCOLLECTION_${category}`}>
          <h3>{category}</h3>
          {nodes.map((n) => <FaqItem item={n} key={`FAQCOLLECTION_${category}_ITEM_${n.id}`} />)}
        </div>
      )
    }
}


