import * as React from "react";
import { graphql } from "gatsby";
import { inject } from "mobx-react";

import {Seo }from "components";
import { SquidexFaqPage } from 'generated/gatsbyApiTypes';
import { getLanguageValue, TraumwandPageComponent, PAGE_VIEW_EVENT } from 'utils';
import { FaqItemCollection } from "features";

export interface QueryData
{
  squidexFaqPage: SquidexFaqPage;
}

@inject("visitorStore")
export default class FAQPage extends TraumwandPageComponent<QueryData> {
  componentWillMount() {
    this.props.visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "faq"
    });
  }
  render() {
    const faqPage = this.props.data.squidexFaqPage;
    return (
      <div className="mb4 ">
        <Seo subTitle={getLanguageValue(faqPage.title)} />
        <h1>{getLanguageValue(faqPage.title)}</h1>
        <FaqItemCollection nodes={faqPage.faqitems}/>
      </div>
    )
  }
}

export const query = graphql`
{
  squidexFaqPage(identity: { eq: "faqpage" }) {
    title {
      de
      nl
      en
    }
    faqitems {
      id
      question {
        de
        nl
        en
      }
      category {
        de
        nl
        en
      }
      answer {
        de
        nl
        en
      }
    }
  }
}
`
