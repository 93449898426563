import * as React from "react";

import { SquidexFaqItem } from "generated/gatsbyApiTypes";
import { getLanguageValue } from "utils";
import { Collapse } from "components"


export interface FaqItemProps {
  item: SquidexFaqItem;
}

export default class FaqItem extends React.Component<FaqItemProps> {
    render() {
      return <Collapse label={getLanguageValue(this.props.item.question)}>
        <div dangerouslySetInnerHTML={{ __html: getLanguageValue(this.props.item.answer)}} />
      </Collapse>
    }
}


